<template>
    <div class="info-modal">
        <div class="content">
            <div class="mb-3">
                <div v-html="translate('info.description')"></div>
            </div>
            <div>
                <nuxt-resource
                    src="images/icons/gdpr-fundamentals.svg"
                    :width="45"
                    :height="45"
                    alt="GDPR fundamentals logo"
                />
                <a href="https://shuftipro.com/quality-guild/" target="_blank">{{ translate('link') }}</a>
            </div>
        </div>

        <UIButton variant="primary" class="w-100 mt-4" @click="hideModal"> {{ translate('button') }} </UIButton>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { UIButton } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        UIButton,
    },
    mixins: [TranslationMixin],

    computed: {
        ...mapGetters({
            modal: 'ui/activeModal',
        }),
    },

    methods: {
        ...mapActions({
            hideModal: 'ui/hideModal',
        }),
    },
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 1.3rem;
}
</style>
<i18n>{}</i18n>
